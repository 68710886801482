import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import RelatedContent from '../../components/RelatedContent'
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import {Image, SlideshowLightbox} from "lightbox.js-react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import DemoSidebar from "../../components/DemoSidebar";

export default function PhotoShowcaseDemoComponent () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  const products = [
    {
      id: 1,
      name: 'Basic Tee',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      color: 'Black',
    },
    {
        id: 2,
        name: 'Basic Tee',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        color: 'White',
      },
      {
        id: 3,
        name: 'Basic Tee',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        color: 'Navy',
      },
  ]

  return <Layout>
    <section className="section section--gradient font-inter bg_white">
      <Header overrideDarkTheme={true} />
    
        <div className="pt-4">
        <main className="flex-grow">

            <section>
            <div className="max-w-6xl mx-auto px-2 sm:px-2">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Main content */}
                <div className="md:flex md:justify-between" data-sticky-container>

                    <DemoSidebar />

                    <div className="md:flex-grow  font-inter">

                      {/* <Header /> */}
                      <div className="text-lg text-gray-600">
                        <h1 className="h1 text-gray-900 mb-4 font-bold">Photo Showcase</h1>
                             <SlideshowLightbox showThumbnails={true} 
                             className="container grid grid-cols-3 gap-2 mx-auto"> 
                              {/* <img className="w-full rounded" src="https://source.unsplash.com/C8ikzkkLLso/1400x1200" />
                              <img className="w-full rounded object-cover" src="https://source.unsplash.com/jOF2TFSNxQI/1400x1200" />  
                              <img className="w-full rounded object-cover" src="https://source.unsplash.com/RsRTIofe0HE/1600x1200" />
                              <img className="w-full rounded" src="https://source.unsplash.com/FIKD9t5_5zQ/1600x1200" />       

                              <img className="w-full rounded" src="https://source.unsplash.com/fHu41_uamys/1600x1200" />
                              <img className="w-full rounded" src="https://source.unsplash.com/Qw3w0oBH63s/1600x1200" />   
                                       */}

                              <img className="w-full h-40 rounded demoImg" src="https://images.pexels.com/photos/13425910/pexels-photo-13425910.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                              <img className="w-full h-40  rounded demoImg object-cover" src="https://images.pexels.com/photos/4275885/pexels-photo-4275885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />  
                              <img className="w-full h-40  rounded demoImg object-cover" src="https://images.pexels.com/photos/13208323/pexels-photo-13208323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                              <img className="w-full h-40  rounded demoImg object-cover" src="https://images.pexels.com/photos/13996896/pexels-photo-13996896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />       

                              <img className="w-full h-40 rounded demoImg object-cover" src="https://images.pexels.com/photos/11407630/pexels-photo-11407630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                              <img className="w-full h-40 rounded demoImg object-cover" src="https://images.pexels.com/photos/13996896/pexels-photo-13996896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />   
                                 
                            </SlideshowLightbox>


      </div>

      {/* Related content */}
        <RelatedContent />
      {/* <Feedback /> */}

    </div>

                </div>

                </div>
            </div>
            </section>

            </main>
        </div>

    </section>
    <Footer overrideDarkTheme={true} />
    </Layout>
    
}